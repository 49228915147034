<template>
    <div class="sell-now-base">
        <b-card no-body class="box-action mb-3">        
            <b-card-body>            
                <b-form autocomplete="off">
                    <b-form-group
                        :label="`Price (${ quoteSymbol })`"
                        label-for="base-price"
                        >
                        <b-form-input
                        id="base-price"
                        readonly
                        v-model="price"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        :label="`Amount (${ baseSymbol })`"
                        label-for="base-amount"
                        >
                        <b-form-input id="base-amount" input-id="base-amount" v-model="amount" required placeholder=""></b-form-input>
                        <b-card-text class="text-right balance">
                            {{ parseFloat(amount) }} {{ baseSymbol }}&nbsp;<b-icon-arrow-right></b-icon-arrow-right>&nbsp;{{ (parseFloat(amount) * parseFloat(price)).toFixed(4) }} {{ quoteSymbol }}
                        </b-card-text>
                    </b-form-group>
                    <b-form-group size="sm">
                        <b-form-input v-model="amount" type="range" min="0" :max="maxAmount" size="sm"></b-form-input>
                        <b-card-text class="text-right avbl">
                            Avbl: {{ maxAmount }} {{ baseSymbol }}
                        </b-card-text>
                        <b-card-text class="text-right max-sell">
                            Max Sell: {{ (parseFloat(maxAmount) * parseFloat(price)).toFixed(4) }} {{ quoteSymbol }}
                        </b-card-text>
                        <b-card-text class="text-right est-fee">
                            Est. Fee: {{ (parseFloat(amount) * parseFloat(price)).toFixed(4) * 2 / 1000 }} {{ quoteSymbol }}
                        </b-card-text>
                    </b-form-group>
                </b-form>

                <div v-if="!loadingData">
                    <b-btn class="btn-custom btn-cancel mr-3" @click="$emit('sell-cancel')">
                        Cancel
                    </b-btn>

                    <b-button
                        :disabled='isDisabled'
                        @click="sell()"
                        class="btn-custom btn-sell mr-3"
                        >
                        Sell
                        <b-spinner v-if="isDisabled" small label="Loading..."></b-spinner>
                    </b-button>                
                </div>
                <b-skeleton v-else type="button"></b-skeleton>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import { ethers } from 'ethers'
import { FTMarket, FTMarketMulticall, FT } from '@baza/sdk'

export default {
    name: 'P2PSell',
    data: () => ({
        base: '',
        baseDecimals: 18,
        baseSymbol: '',
        quoteDecimals: 18,
        quoteSymbol: '',
        marketAddress: '',
        price: 0,
        amount: 0,
        maxAmount: 0,
        isBaseNative: false,
        isDisabled: false,
        isBaseApproved: false,
        market: {},
        loadingData: true
    }),
    props: [ 'baseLink', 'quoteLink', 'bidData' ],
    created: async function () {
        this.market = await FTMarket.setMarket(this.baseLink, this.quoteLink, this.provider)
        let calls = await FTMarketMulticall.setMulticall(this.market.baseToken, this.market.quoteToken, this.provider)

        this.marketAddress = this.market.marketAddress
        this.base = this.market.baseToken
        this.isBaseNative = this.market.isBaseNative
        this.address = await this.provider.getSigner().getAddress()

        let { baseDecimals, baseSymbol } = await calls.getBaseInfo()
        this.baseDecimals = baseDecimals
        this.baseSymbol = baseSymbol

        let { quoteDecimals, quoteSymbol } = await calls.getQuoteInfo()
        this.quoteDecimals = quoteDecimals
        this.quoteSymbol = quoteSymbol

        this.price = ethers.utils.formatUnits(this.bidData.price, quoteDecimals)

        let baseFT = new FT(this.base, this.provider)
        this.isBaseApproved = this.isBaseNative || !(await baseFT.allowance(this.address, this.marketAddress)).eq(0)
        this.maxAmount = ethers.utils.formatUnits(await baseFT.balanceOf(this.address), baseDecimals)
        let bidAmount  = ethers.utils.formatUnits(this.bidData.amount, baseDecimals)
        this.maxAmount = (this.maxAmount < bidAmount) ? this.maxAmount : bidAmount
        this.amount = this.maxAmount

        this.loadingData = false
    }, 
    methods: {
        async baseApprove() {
            let baseFT = new FT(this.base, this.provider)
            let tx = await baseFT.approve(this.marketAddress)
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
            this.isQuoteApproved = true
        },
        async sell () {
            try {
                if (!this.isBaseApproved) {
                    await this.baseApprove()
                }
                this.isDisabled = true
                let amount = ethers.utils.parseUnits(this.amount, this.baseDecimals)
                let price = ethers.utils.parseUnits(this.price, this.quoteDecimals)
                let tx = await this.market.sell(this.bidData.orderId, amount, price)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$emit('sell-success', {
                    txHash: tx.hash,
                    price: this.price,
                    txText: 'sold'
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        }
    }
};
</script>

<style lang="scss">
    .sell-now-base{        
        .balance{
            font-size: 14px;
            color: #5E6C84;
            font-weight: normal;
            margin-top: 10px;
            margin-bottom: 0;
            justify-content: end;
        }
        .avbl{
            font-size: 14px;
            color: #5E6C84;
            font-weight: normal;
            margin-top: 0px;
            margin-bottom: 0px;
            justify-content: end;
        }
        .max-sell{
            font-size: 14px;
            color: #5E6C84;
            font-weight: normal;
            margin-top: 0px;
            margin-bottom: 0px;
            justify-content: end;
        }
        .est-fee{
            font-size: 14px;
            color: #5E6C84;
            font-weight: normal;
            margin-top: 0px;
            justify-content: end;
        }
        .base-name{
            font-size: 30px;
            font-weight: 700;
        }
        .box-action{
            background-color: #F4F5F7;
            border-radius: 8px;
            border: none;
            label{
                color:#9D22C1;
                text-transform: uppercase;
                font-size: 12px;
            }
            #base-amount,#base-price{
                height: 56px;
                border-radius: 8px;
                border: 1px solid #DFE1E6;
            }
        }
        .btn-custom{
            padding: 8px 16px;
            border: none;
            border-radius: 30px;
            font-weight: 400;
            min-width: 80px;
            &:focus{
                box-shadow: none;
            }         
        }
        .btn-sell{
            color: #fff;
            background-color: #DE350B;
            &:hover{
                background-color: #DE350B;
            }
            &.disabled,&:disabled{
                background-color: #DE350B;
            }  
        }
        .btn-cancel{
            color: #196EC2;
            background-color: rgba(25, 110, 194, 0.1);
            &:hover{
                background-color: rgba(25, 110, 194, 0.295);
                color: #196EC2;
            }
        }
        .no-item {
            text-align: center;
            width: 100%;
            display: block;
            color: #5E6C84;
            margin-top: 30px;
            margin-bottom: 60px;

            img {
                max-width: 80px;
            }
        }
        .table {
            thead {
                color: #7A869A;
                font-size: 10px;
                text-transform: uppercase;

                th {
                    font-weight: 500;
                    border-bottom: none;
                }
            }

            tbody {
                font-size: 12px;
                color: #091E42;
            }
        }
    }
</style>
